import type { UiHeaderColor } from '~/modules/rawlplug-ui/components/UiHeader/useUiHeader'

/**
 * These classes aren't detected by Tailwind, so they might
 * not work if they aren't used elsewhere in app.
 *
 * To make sure they work properly, include classes in
 * safelist array in tailwind.config.js
 */
export function getTypo3BackgroundColor(background: string) {
  switch (background) {
    case 'color-gradient-blue':
      return 'bg-gradient-bright-blue'
    case 'color-gradient-dark-blue':
      return 'bg-gradient-darker-blue'
    case 'color-dark-blue':
      return 'bg-blue'
    case 'color-gray':
      return 'bg-grey-50'
    case 'color-white':
      return 'bg-white'
    case 'color-gradient-green':
      return 'bg-gradient-green'
    case 'color-gradient-purple':
      return 'bg-gradient-purple'
    case 'color-light-blue':
      return 'bg-blue-200'
    case 'color-pale-blue':
      return 'bg-grey-150'
    case 'color-light-blue-tint':
      return 'bg-blue-500'
    default:
      return ''
  }
}

export function getTypo3WatermarkColor(background: string) {
  switch (background) {
    case 'color-gray':
    case 'color-white':
      return 'text-grey-100 opacity-30'
    default:
      return 'text-white opacity-15'
  }
}

export function getTypo3HeaderColor(headerColor?: UiHeaderColor | null) {
  switch (headerColor) {
    case 'color-white':
      return 'text-white'
    default:
      return 'text-blue'
  }
}
